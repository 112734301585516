'use strict';

require('bootstrap/dist/css/bootstrap.min.css');
require('angular-tablesortesize/tablesort.css');
require('angularjs-datepicker/dist/angular-datepicker.min.css');
require('angular-busy/dist/angular-busy.min.css');
require('animate.css/animate.min.css');
require('./ribbons.css');
require('./hue.css');
require('./snow.css');
require('./app.css');

require('angular');
require('angular-route');
var CryptoJS = require('crypto-js');
require('alertify.js/dist/js/ngAlertify.js');
require('angularjs-datepicker');
require('angular-ui-bootstrap/dist/ui-bootstrap-tpls.js'); // Note that angular-ui-bootstrap 2.x won't work in this case
require('angular-tablesortesize');
require('angular-busy');
var $ = require('jquery-browserify');
require('colresizable/colResizable-1.6.min.js');
require('particles.js/particles.js');
var Stats = require('stats.js/build/stats.min.js');
var dateUtils = require('./date-utils.js');

var unlimited = {
  date: '9999-01-01T00:00:00.000Z',
  label: 'UNLIMITED'
};

var app = angular.module('app', ['ngRoute', 'tableSort', 'ngAlertify', '720kb.datepicker', 'ui.bootstrap', 'cgBusy'])
.config(function($routeProvider, $locationProvider, $httpProvider, tableSortConfigProvider) {
  'ngInject';

  var filterString = "<div class='row'>";
  filterString +=      "<div class='col-sm-4 col-md-3 col-sm-offset-8 col-md-offset-9'>";
  filterString +=        "<div class='form-group has-feedback'>";
  filterString +=          "<input type='search' class='form-control' placeholder='filter {{ITEM_NAME_PLURAL}}' ng-model='FILTER_STRING'/>";
  filterString +=        "</div>";
  filterString +=      "</div>";
  filterString +=    "</div>";
  tableSortConfigProvider.filterTemplate = filterString;

  var pagerString = "<div class='text-right'>";
  pagerString += "<small class='text-muted'>Showing {{CURRENT_PAGE_RANGE}} {{FILTERED_COUNT === 0 ? '' : 'of'}} ";
  pagerString +=        "<span ng-if='FILTERED_COUNT === TOTAL_COUNT'>{{TOTAL_COUNT | number}} {{TOTAL_COUNT === 1 ? ITEM_NAME_SINGULAR : ITEM_NAME_PLURAL}}</span>";
  pagerString +=        "<span ng-if='FILTERED_COUNT !== TOTAL_COUNT'>{{FILTERED_COUNT | number}} {{FILTERED_COUNT === 1 ? ITEM_NAME_SINGULAR : ITEM_NAME_PLURAL}} (filtered from {{TOTAL_COUNT | number}})</span>";
  pagerString +=      "</small>&nbsp;";
  pagerString +=      "<uib-pagination style='vertical-align:top; margin-top:0;' ng-if='ITEMS_PER_PAGE < TOTAL_COUNT' ng-model='CURRENT_PAGE_NUMBER' total-items='FILTERED_COUNT' items-per-page='ITEMS_PER_PAGE' max-size='5' force-ellipses='true'></uib-pagination>";
  pagerString +=      "&nbsp;";
  pagerString +=      "<div class='form-group' style='display:inline-block;'><select class='form-control' ng-model='ITEMS_PER_PAGE' ng-options='opt as (opt + \" per page\") for opt in PER_PAGE_OPTIONS'></select></div>";
  pagerString +=    "</div>";
  tableSortConfigProvider.paginationTemplate = pagerString;

  var checkLoggedin = function($q, $timeout, $http, $location) {
    'ngInject';

    var deferred = $q.defer();

    $http.get('/loggedin').then(function(response) {
      var user = response.data;
      if (user !== '0')
        deferred.resolve();
      else {
        deferred.reject();
        $location.url('/login');
      }
    });

    return deferred.promise;
  };

  $httpProvider.interceptors.push(function($q, $location) {
    'ngInject';

    return {
      response: function(response) {
        return response;
      },
      responseError: function(response) {
        if (response.status === 401) {
          $location.url('/login');
        }
        return $q.reject(response);
      }
    };
  });

  $routeProvider
    .when('/', {
      templateUrl: 'main.html',
      controller: 'MainCtrl',
      resolve: {
        loggedin: checkLoggedin
      }
    })
    .when('/login', {
      templateUrl: 'login.html',
      controller: 'LoginCtrl'
    })
    .otherwise({
      redirectTo: '/'
    });
})
.filter('unlimitedDateFilter', function($filter) {
  'ngInject';

  return function(input) {
    if (input === unlimited.date) {
      return unlimited.label;
    }
    return input;
  };
})
.filter('limitToFirstLine', function() {
  'ngInject';

  return function(input) {
    input = input || '';
    var firstLine = input.split('\n', 1)[0];
    if (firstLine !== input) {
      return firstLine + '...';
    } else {
      return input;
    }
  };
})
.directive('dateDirective', function($filter) {
  'ngInject';

  return {
    require: 'ngModel',
    link: function(scope, element, attrs, ngModelController) {
      ngModelController.$parsers.push(function(data) {
        // Convert data from view format to model format
        if (data === unlimited.label) {
          return unlimited.date;
        }
        return $filter('date')(new Date(data), 'yyyy-MM-dd');
      });

      ngModelController.$formatters.push(function(data) {
        // Convert data from model format to view format
        if (data === unlimited.date) {
          return unlimited.label;
        }
        return $filter('date')(data, 'mediumDate');
      });
    }
  }
})
.run(function($rootScope, $http, $location) {
  'ngInject';

  $rootScope.logout = function() {
    $http.post('/logout')
    .then(function() {
      $location.url('/login');
    });
  };

  // Expose global object to be able to use it from angular expressions
  $rootScope.dateUtils = dateUtils;
});

app.controller('LoginCtrl', function($scope, $http, $location, alertify) {
  'ngInject';

  $scope.user = {};

  $scope.login = function() {
    $http.post('/login', {
      username: $scope.user.username,
      password: CryptoJS.MD5($scope.user.password).toString(CryptoJS.enc.Hex)
    })
    .then(function() {
      $location.url('/');
    }, function() {
      $location.url('/login');
      alertify.error('Authentication failed');
    });
  };

  angular.element(document).ready(function() {
    if (dateUtils.isChristmasTime()) {
      $('body').addClass('winter-background');
      $('<div/>', {
        'class' : 'snow'
      }).appendTo('body');
    } else {
      particlesJS.load('particles-js', 'particles.json');

      var stats = new Stats();
      stats.showPanel(0); // 0: fps, 1: ms, 2: mb, 3+: custom
      stats.dom.style.cssText = '';
      stats.dom.style.position = 'absolute';
      stats.dom.style.top = '0px';
      stats.dom.style.right = '0px';
      document.body.appendChild(stats.dom);
      function animate() {
        stats.begin();
        stats.end();
        requestAnimationFrame(animate);
      }
      requestAnimationFrame(animate);
    }
  });
});

app.controller('MainCtrl', function($scope, $http, alertify, $uibModal) {
  'ngInject';

  $scope.licenses = [];

  $scope.search = function() {
    $scope.licensesPromise = $http.post('/licenses', {
      company_name: $scope.company_name,
      product_name: $scope.product_name,
      comment: $scope.comment
    }).then(function(response) {
      var licenses = response.data;
      $scope.licenses = licenses;
    });
  };

  $scope.getTemplate = function(license) {
    if ($scope.selected !== undefined) {
      if (license.id === $scope.selected.id) {
        return 'edit';
      }
    }
    return 'display';
  };

  $scope.editLicense = function(license) {
    $scope.selected = angular.copy(license);
  };

  $scope.deleteLicense = function(license) {
    alertify.confirm("Are you sure?", function () {
      $scope.licensesPromise = $http.post('/deletelicense', license)
      .then(function(response) {
        if (response.status === 202) { // Accepted
          alertify.success('Keep calm and wait for an approval');
        } else {
          var idx = $scope.licenses.indexOf(license);
          if (idx > -1) {
            $scope.licenses.splice(idx, 1);
          }
          alertify.success('License deleted');
        }
      });
    });
  };

  $scope.showUpdates = function(license) {
    var modalInstance = $uibModal.open({
      ariaLabelledBy: 'updates-modal-title',
      ariaDescribedBy: 'updates-modal-body',
      templateUrl: 'UpdatesModalContent.html',
      controller: 'UpdatesModalCtrl',
      controllerAs: '$ctrl',
      size: 'lg',
      resolve: {
        license: function() {
          return license;
        }
      }
    });
  };

  $scope.makeUnlimited = function(idx) {
    $scope.selected.license_end = unlimited.date;
  };

  $scope.saveLicense = function(idx) {
    $scope.licensesPromise = $http.post('/savelicense', $scope.selected)
    .then(function(response) {
      if (response.status === 202) { // Accepted
        alertify.success('Keep calm and wait for an approval');
      } else {
        for (var i = 0; i < $scope.licenses.length; ++i) {
          if ($scope.licenses[i].id === $scope.selected.id) {
            $scope.licenses[i] = angular.copy($scope.selected);
            break;
          }
        }
        alertify.success('License saved');
      }
      $scope.reset();
    });
  };

  $scope.reset = function() {
    $scope.selected = undefined;
  };

  angular.element(document).ready(function() {
    // We want to apply our winter theme to the login page only
    $('body').removeClass('winter-background');
    $('.snow').remove();

    $('#licensesTable').colResizable({
      disable: true
    });

    $('#licensesTable').colResizable({
      liveDrag: true
    });
  });

  $scope.search();
});

angular.module('app').controller('UpdatesModalCtrl', function($http, $uibModalInstance, license) {
  'ngInject';

  var $ctrl = this;
  $ctrl.updates = [];

  $ctrl.showUpdates = function() {
    $ctrl.updatesPromise = $http.post('/updates', {
      company_name: license.company_name,
      product_name: license.product_name
    })
    .then(function(response) {
      $ctrl.updates = response.data;
    });
  };

  $ctrl.close = function() {
    $uibModalInstance.dismiss('cancel');
  };

  $ctrl.showUpdates();
});
