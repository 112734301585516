'use strict';

var nextMonth = function() {
  var now = new Date();
  if (now.getMonth() == 11) {
    return new Date(now.getFullYear() + 1, 0, 1);
  } else {
    return new Date(now.getFullYear(), now.getMonth() + 1, 1);
  }
}

var isChristmasTime = function() {
  var now = new Date();
  return (now.getMonth() == 11 && now.getDate() >= 15) || (now.getMonth() == 0);
}

exports.nextMonth = nextMonth;
exports.isChristmasTime = isChristmasTime;
